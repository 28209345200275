import React, { useState } from "react";
import { useRouter } from "next/router";

import Card from "atoms/Card";
import Text from "atoms/Text";
import Carousel from "atoms/Carousel";
import IconArrowPrevious from "atoms/SvgIcons/IconArrowPrevious.svg"
import IconArrowNext from "atoms/SvgIcons/IconArrowNext.svg"
import Button from "atoms/Button";

import ClinicServices from "molecules/ClinicServices";
import ClinicsDoctorCard from "molecules/ClinicsDoctorCard";

import ClinicProfileDataSection from "organisms/ClinicProfileDataSection";
import Alert from "molecules/Alert";
import { getAuth } from 'services/identity.service';
const auth = getAuth();
const isLoggedIn =  (auth?.user?.userType === 1 || auth?.user?.userType === undefined);


export const ClinicTimings = ({ className = "", timings, ...props }) => {
    return (
        <div className={`${className}`}>
            <Text className={`text-xs font-semibold text-gray-900 mb-2`}>
                Clinic Timings
            </Text>
            <Text className={`text-sm font-normal text-other-blue-350 line-clamp-2`}>
                {timings}
            </Text>
        </div>
    );
};

export const ClinicsDoctors = ({
    className = "",
    isShowArrow = false,
    slideClass = "",
    doctorsList = [],
    isAyurvedaPage=false,
    navElement,
    ...props
}) => {
    const router = useRouter();
    
    return doctorsList?.length ? (
        <div className="w-full md:w-56 relative">
            {isShowArrow ?
                <div className="w-full hidden md:block">
                    <button
                        className={`${navElement}Arrow--prev absolute -left-4 top-13 translate-x-2 z-2 group`}
                    >
                        <IconArrowPrevious
                            className={`w-4.5 h-4.5 p-1 text-center border-1.4 rounded-full bg-basic-white border-primary1-500 text-primary1-900`}
                            color="#24ACDA"
                            strokeWidth="10"
                        />
                    </button>
                    <button
                        className={`${navElement}Arrow--next absolute -right-4 top-12 -translate-x-2 z-2 group`}
                    >
                        <IconArrowNext
                            className={`w-4.5 h-4.5 p-1 text-center border-1.4 rounded-full bg-basic-white border-primary1-500 text-primary1-900`}
                            color="#24ACDA"
                            strokeWidth="10"
                        />
                    </button>
                </div>
            : null}
            <div type="static" className="w-full">
                <Carousel
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                            slidesPerGroup: 1,
                        },
                        320: {
                            slidesPerView: 1.25,
                            slidesPerGroup: 1,
                        },
                        360: {
                            slidesPerView: 1.45,
                            slidesPerGroup: 1,
                        },
                        390: {
                            slidesPerView: 1.7,
                            slidesPerGroup: 1,
                        },
                        768: {
                            slidesPerView: 2,
                            slidesPerGroup: 1,
                        },
                        1024: {
                            slidesPerView:  isAyurvedaPage ? 1.6 : 2,
                            slidesPerGroup: 1,
                        },
                    }}
                    centeredSlides={false}
                    isShowArrow={isShowArrow}
                    spaceBetween={14}
                    navigationElement={navElement}
                    className={`${className}`}
                    swiperSlideClass={slideClass}
                    sliderList={doctorsList.map((doctor) => {
                        return (
                            <ClinicsDoctorCard
                                className={`w-56 overflow-hidden`}
                                fullNameWithSalutation={doctor?.fullNameWithSalutation}
                                profilePic={doctor?.User?.tbl_doctor_profile?.profile_pic}
                                rating={doctor?.rating}
                                specializations={doctor?.specializations}
                                experience={doctor?.experienceText}
                                href={doctor?.href}
                            />
                        );
                    })}
                />
            </div>
        </div>
    ) : null;
};

const ClinicSummary = ({
    className = "",
    screenSize,
    clinicData,
    forDetails = false,
    isShowVerifiedIcon,
    isAyurvedaPage,
    bookingEnabled,
    isClinicListing=false,
    ...props
}) => {
    const [alertMessage, setAlertMessage] = useState(null);
    const router = useRouter();
    
    return (
        <Card className={`${className} bg-basic-white rounded-sm  p-4`}>
            {alertMessage?.message ? (
                <div className={`bottom-5 sticky z-9`}>
                    <Alert className="z-2" type={alertMessage?.type} effect="slideDown" resetAlertCallback={() => setAlertMessage(null)}>
                        {alertMessage?.message}
                    </Alert>
                </div>
            ) : null}
            <ClinicProfileDataSection
                screenSize={screenSize}
                clinicProfileData={clinicData}
                forDetails={forDetails}
                setAlertMessage={setAlertMessage}
                isShowVerifiedIcon
                isAyurvedaPage={isAyurvedaPage}
                isClinicListing={isClinicListing}
            />
            {
                (screenSize < 768)
                    ?
                <>
                    <div className="mb-3">
                        {clinicData?.servicesList?.length ?
                            <ClinicServices
                                services={clinicData?.servicesList}
                            />
                        : null}
                        {clinicData?.timingsList?.length ?
                            <ClinicTimings
                                className=""
                                timings={clinicData?.timingsList}
                            />
                        : null}
                    </div>
                  {forDetails==false  && <ClinicsDoctors
                        className="mb-4"
                        doctorsList={clinicData?.clinicsDoctors}
                        isAyurvedaPage={isAyurvedaPage}
                    />}
                </>
                    :
                null
            }
            {screenSize <= 820 && isLoggedIn && forDetails==false && bookingEnabled==1 ?
                <Button
                    className={`
                        bg-primary1-500
                        rounded-sm-0.5
                        px-6
                        py-3
                        mx-auto
                        w-[182px]
                        h-[36px]
                    `}
                    variant="buttonSize--extraSmall_v3"
                    size="extraSmall_v3"
                    onClick={() => router.push(clinicData?.href)}
                >
                    Book Clinic Visit
                </Button>
            : null}
        </Card>
    );
};

export default ClinicSummary;