import React from "react";

import Card from "atoms/Card";
import Avatar from "atoms/Avatar";
import Text from "atoms/Text";
import IconStar from "atoms/SvgIcons/IconStar.svg";
import LinkAnchor from "atoms/LinkAnchor";
import { useRouter } from "next/router";

const ClinicsDoctorCard = ({
    className,
    fullNameWithSalutation,
    profilePic = "",
    rating = "",
    specializations = "",
    experience = "",
    href = "",
    ...props
}) => {
    const router = useRouter();
    return (
        <Card
            className={`
                ${className}
                flex
                gap-2.5
                bg-basic-white
                rounded-2xl
                border
                border-gray-200
                p-2.5
                left-4
            `}
            onClick={(e) => {e.stopPropagation(); router.push(href)}}
        >
            <Avatar
                className={`w-10 h-10 rounded-full`}
                rounded="full"
                src={profilePic ? process.env.NEXT_PUBLIC_PHP_BUCKET_URL + profilePic : ""}
            />
            <div className="flex flex-col">
                    <Text className={`text-xs font-semibold text-other-blue-350 line-clamp-1`}>
                        {fullNameWithSalutation}
                    </Text>
                <div className="flex flex-col gap-0.5">
                    <div className="flex gap-2 justify-start items-center">
                        <IconStar className="text-yellow-400 w-3.5 h-3.5" />
                        <Text
                            className="text-xs font-Open-Sans font-normal text-other-blue-350"
                        >
                            {(!rating || rating < 1) ? "New" : rating + "/5"}
                        </Text>
                    </div>
                    <div className="flex flex-col w-37.5 gap-0.75">
                        <Text className={`text-xs leading-4 font-normal text-gray-600 line-clamp-2`}>
                            {specializations}
                        </Text>
                        <Text className={`text-xs leading-4 font-normal text-gray-600 mb-0.5`}>
                            {experience}
                        </Text>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default ClinicsDoctorCard;